/* General styles */
body {
    background-color: #282c34;
    color: white;
    margin: 0;
    font-family: Arial, sans-serif;
}

.Passion {
    text-align: center;
    padding: 20px;
}

input {
    width: 90%;
    max-width: 500px;
    height: 35px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 20px;
    margin-bottom: 30px;
    padding: 5px;
}

button {
    width: 90%;
    max-width: 220px;
    height: 50px;
    font-size: 25px;
    background-color: yellow;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: darkorange;
}

.special {
    display: inline-block;
    border: solid white 5px;
    padding: 10px;
    border-radius: 25px;
    width: 80%;
    max-width: 600px;
    box-sizing: border-box;
}

.sharebuttons {
    text-align: center;
}

/* FAQ container styles */
.faq-container {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    padding: 0 20px;
    box-sizing: border-box;
}

.faq-item {
    background: yellow;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.faq-question {
    font-weight: bold;
    color: black;
    position: relative;
    font-size: larger;
    padding-right: 30px;
    /* Add padding to the right for the arrow */
}

.arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    font-size: larger;
    /* Change font size to match other text */
}

.faq-answer {
    padding-top: 10px;
    color: black;
    font-size: larger;
}

.faq-section {
    text-align: left;
    padding: 0 20px;
}

.faq-heading {
    text-align: center;
    font-size: 30px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {

    .special,
    .faq-container,
    .faq-item {
        width: 100%;
        padding: 10px;
    }

    input {
        width: 90%;
        font-size: 18px;
    }

    button {
        width: 90%;
        font-size: 20px;
    }

    .faq-question {
        font-size: medium;
        padding-right: 30px;
        /* Maintain right padding for the arrow */
    }

    .arrow {
        font-size: medium;
        /* Adjust font size of arrow for smaller screens */
    }

    .faq-answer {
        font-size: medium;
    }

    .left-align-content {
        font-size: 18px;
        padding: 0 10px;
    }

    .faq-heading {
        font-size: 26px;
    }
}

@media (max-width: 480px) {

    .faq-question,
    .faq-answer {
        font-size: small;
        padding-right: 30px;
        /* Maintain right padding for the arrow */
    }

    .arrow {
        font-size: small;
        /* Adjust font size of arrow for smaller screens */
    }

    .faq-heading {
        font-size: 24px;
    }

    .left-align-content {
        font-size: 16px;
    }

    input,
    button {
        width: 95%;
    }
}

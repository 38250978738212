.header-content {
  display: flex;
  justify-content: space-between;
  background-color: #001f3f;
  flex-wrap: nowrap;
  height: 120px;
}

.edit-button.disabled {
  background-color: #ccc; /* Greyed out color */
  color: #666; /* Text color for disabled state */
  cursor: not-allowed; /* Show a not-allowed cursor */
  pointer-events: none; /* Prevent interactions */
}

.header-logo-and-h1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header-nav-center {
  background-color: #001f3f;
  max-width: fit-content;
  padding: 10px;
  padding-right: 0px;
  margin: 10px;
  margin-top: 25px;
  text-align: center;
  line-height: 0px;
}

.header-website-name {
  background-color: #001f3f;
  max-width: fit-content;
  padding: 10px;
  padding-right: 10px;
  margin: 10px;
  margin-top: 25px;
  text-align: left;
  line-height: 50px;
}

.header-h1 {
  color: white;
  font-size: 24px;
}

.header-nav-right {
  background-color: #001f3f;
  max-width: fit-content;
  padding: 10px;
  padding-right: 0px;
  margin: 10px;
  text-align: center;
  line-height: 100px;
}

.header-nav-logo {
  background-color: #001f3f;
  line-height: 10px;
  margin: 10px;
  padding: 10px;
}

.header-logo-image {
  width: 355px;
  height: 106.1px;
  margin-top: -15px;
  margin-left: 17px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: white;
}

nav ul li {
  margin-right: 20px;
  position: relative;
}

nav ul li a {
  color: #1f2533;
  text-decoration: none;
  font-weight: bold;
  background-color: #ffd700;
  padding: 10px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 20px;
  line-height: 50px;
  height: 25px;
  top: 5px;
}

nav ul li a:hover {
  background-color: #ffe86d;
}

.dropdown > a:after {
  content: "▼";
  font-size: 80%;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(1.5px);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffd700;
  color: white;
  min-width: 320px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 11;
  padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  /* Two columns */
  gap: 10px;
  max-height: 60vh;
  /* Ensure it doesn't exceed viewport height */
  overflow-y: auto;
  /* Make it scrollable vertically */
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
  scrollbar-width: thin;
  scrollbar-color: #001f3f #ffd700;
}

.dropdown-content a {
  color: #1f2533;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  padding: 0px 15px;
  padding-bottom: 22px;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ffe86d;
  border-radius: 5px;
  color: black;
}

.dropdown.open .dropdown-content {
  display: grid;
  border-radius: 10px;
  margin-top: 10px;
  border: solid 5px #ffe86d;
}

.dropdown.open > a {
  color: #1f2533;
  text-decoration: none;
}

.chat-now {
  background-color: #ffd700;
  width: 93px;
  height: 30px;
  color: #001f3f;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  /* vertical-align: middle; */
  line-height: 0px;
  margin-top: 18px;
}

.signup-login {
  font-size: 15px;
}

.chat-now:hover {
  background-color: #ffe86d;
}

.edit-button {
  background-color: orange;
  width: 150px;
  height: 40px;
  font-weight: bold;
  color: #001f3f;
  border: none;
  padding: 5px;
  padding-right: 5px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 1;
  margin-top: -15.7px;
}

.edit-button:hover {
  background-color: #ffe86d;
  color: #001f3f;
}

/* Disable scrolling for the body when the menu is open */
.no-scroll {
  overflow: hidden;
}

.dropdown:hover .dropdown-content {
  display: grid; /* Show the dropdown content */
  border-radius: 10px;
  margin-top: 10px;
  border: solid 5px #ffe86d;
}

/* Customize the scrollbar */
.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: #ffd700;
  border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #001f3f;
  border-radius: 10px;
  border: 2px solid #ffd700;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background-color: #003f7f;
}

.dropdown-content::-webkit-scrollbar-thumb:active {
  background-color: #002f5f;
}

.signup-and-balance-container {
  display: flex;
  flex-direction: column;
}

/* --------------------------------------------------------- */
/* LOGIN DROPDOWN STYLES */
/* --------------------------------------------------------- */

.dropdown-login > a:after {
  content: "▼";
  font-size: 80%;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(1.5px);
}

.dropdown-login-content {
  display: none;
  position: absolute;
  background-color: #ffd700;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 11;
  max-height: 60vh;
  /* Ensure it doesn't exceed viewport height */
  overflow-y: auto;
  /* Make it scrollable vertically */
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
  scrollbar-width: thin;
  scrollbar-color: #001f3f #ffd700;
}

.dropdown-login-content a {
  color: #1f2533;
  background-color: #ffd700;
  text-decoration: none;
  /* display: block; */
  white-space: nowrap;
  /* padding: 0px 15px; */
  /* padding-bottom: 22px; */
  text-align: left;
  margin-top: 0px;
}

.dropdown-login-content a:hover {
  background-color: #ffe86d;
  border-radius: 5px;
  color: black;
  width: 85%;
}

.dropdown-login.open .dropdown-login-content {
  display: grid;
  border-radius: 10px;
  margin-top: 10px;
  border: solid 5px #ffe86d;
  position: absolute;
  left: -15px;
  height: auto;
  padding: 5px;
  width: 120px;
}

.dropdown-login.open > a {
  color: #1f2533;
  text-decoration: none;
}

.dropdown-login {
  display: none;
}

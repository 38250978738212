/* Rest of the page styles */
body {
  background-color: #282c34;
  color: white;
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Ensure the parent container for the selects uses flexbox */
.special .date-selectors {
  display: flex;
  justify-content: center;
  /* Center align the selects */
  align-items: center;
  /* Vertically center the selects */
  gap: 10px;
  /* Adjust the gap between elements as needed */
  margin-top: 10px;
  /* Add some space above the selectors */
}

.special1 .date-selectors {
  display: flex;
  justify-content: center;
  /* Center align the selects */
  align-items: center;
  /* Vertically center the selects */
  gap: 10px;
  /* Adjust the gap between elements as needed */
  margin-top: 10px;
  /* Add some space above the selectors */
}

.error-message {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Adjustments for select elements */
select {
  width: 150px;
  /* Adjust width as needed */
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
  /* Adjust font size as needed */
  margin: 10px;
  /* Remove default margin */
}

button {
  width: 220px;
  height: 50px;
  font-size: 25px;
  background-color: #007bff;
  /* Set button color */
  color: white;
  /* Text color */
  border: none;
  /* Remove border */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition */
}

.label {
  font-size: large;
}

.special {
  display: inline-block;
  border: solid white 5px;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}

.input-container {
  display: flex;
}

@media (max-width: 600px) {
  .input-container {
    display: flex;
    flex-direction: column;
  }
}

#box {
  flex: 1; /* Each box takes equal width */
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.special1 {
  border: solid #ffd700 5px;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 5);
}

button:hover {
  background-color: #0056b3;
  /* Darker background color on hover */
}

.highlight {
  color: darkgreen;
}

/* Left-align content below the Submit button */
.left-align-content {
  text-align: left;
  margin-left: 100px;
  margin-right: 20px;
  /* Add margin to the left */
  font-size: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  /* This makes the content expand to fill available space */
  padding: 20px;
}

.sharebuttons {
  text-align: center;
}

.faq-container {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.faq-item {
  background: yellow;
  width: 90%;
  max-width: 900px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  color: black;
  position: relative;
  font-size: larger;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
}

.faq-answer {
  padding-top: 10px;
  color: black;
  font-size: larger;
}

.faq-section {
  text-align: left;
  padding: 5px;
}

.faq-heading {
  text-align: center;
  font-size: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .special {
    padding: 10px;
    border-radius: 15px;
  }

  .special .date-selectors {
    flex-direction: column;
    gap: 5px;
  }

  .special1 {
    padding: 10px;
    border-radius: 15px;
  }

  .special1 .date-selectors {
    flex-direction: column;
    gap: 5px;
  }

  select {
    width: 100%;
    margin: 5px 0;
  }

  button {
    width: 100%;
    font-size: 20px;
  }

  .left-align-content {
    margin: 20px;
    font-size: 18px;
  }

  .faq-item {
    width: 100%;
    max-width: 100%;
  }

  .faq-heading {
    font-size: 24px;
  }
}

/* HomeMobile.css */

.home-container2 {
  padding: 20px;
}

.card-container2 {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between cards */
}

.card2 {
  background-color: #f9f9f9;
  border: 5px solid #ffd700;
  border-radius: 8px;
  margin: auto;
  height: 85px;
  max-width: 280px;
  padding: 10px;
  padding-top: 45px;
  margin-bottom: 46px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  z-index: 1;
}

.card2::before {
  content: "";
  display: block;
  height: 80px;
  background-color: #ffd700;
  width: 205px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  z-index: -1;
}

.card-heading2 {
  font-size: 1.4em;
  margin: 0;
  color: #001f3f;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 200px;
}

.card-description2 {
  font-size: 1em;
  color: #001F3F;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-link2 {
  text-decoration: none; /* Remove underline */
  color: #007bff; /* Link color */
}

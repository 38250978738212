.about-us {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.about-us-img {
  width: 100%;
  max-width: 250px;
}

#image1,
#image2,
#image3,
#image4 {
  border: white;
}

#image1 {
  width: 250px;
  height: 250px;
  margin: auto;
}

#image2 {
  width: 250px;
  height: 250px;
  margin: auto;
}

#image3 {
  width: 230px;
  height: 68.74px;
  margin: auto;
}

#image4 {
  width: 230px;
  height: 68.74px;
  margin: auto;
}

.about-us-h1 {
  text-align: center;
  color: #001f3f;
  grid-column: span 2;
}

.column {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.section h2 {
  text-align: center;
  margin-bottom: 10px;
}

.section img {
  height: auto;
}

.section p {
  text-align: left;
}

.vision-mission-p {
  margin-left: 40px;
  margin-right: 40px;
  color: #001f3f;
  font-size: 25px;
}

@media (max-width: 650px) {
  .about-us {
    grid-template-columns: 1fr;
  }

  .column {
    display: contents;
  }

  .section {
    order: unset;
  }

  #welcome {
    order: 1;
  }
  #image3 {
    order: 2;
    margin: 50px auto;
  }
  #services {
    order: 3;
  }
  #image1 {
    order: 4;
  }
  #founder {
    order: 5;
  }
  #image4 {
    order: 6;
    margin: 50px auto;
  }
  #unique {
    order: 7;
  }
  #image2 {
    order: 8;
  }
}

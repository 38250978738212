/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Keeps the semi-transparent black background */
  color: white;
  z-index: 1000;
}

.loader-gif {
  width: 320px; /* Adjust as needed */
}

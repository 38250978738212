.loshu-grid {
  display: grid;
  grid-template-columns: repeat(3, 50px); /* Fixed width for each column */
  grid-template-rows: repeat(3, 50px); /* Fixed height for each row */
  gap: 0; /* No gap between cells */
  margin: 20px auto; /* Center align the grid horizontally */
  justify-content: center; /* Center align the grid horizontally */
  align-items: center; /* Center align the grid vertically */
  height: 100%; /* Make sure the container takes full height */
  width: max-content; /* Adjust the width to fit the content */
}

.grid-cell {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box; /* Ensure border is included in width/height */
}

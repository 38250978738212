.BestDayToFast {
    text-align: center;
}

.content {
    color: black;
}

body {
    background-color: #282c34;
    color: black;
}

.label {
    font-size: large;
}

.sharebuttons {
    text-align: center;
}

.special {
    display: inline-block;
    border: solid white 5px;
    color: black;
    padding: 10px;
    border-radius: 25px;
}

select {
    width: 200px;
    height: 40px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 20px;
    margin-bottom: 30px;
}

button {
    width: 220px;
    height: 50px;
    font-size: 25px;
    background-color: yellow;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: darkorange;
}

.highlight {
    color: darkgreen;
    font-weight: bold;
}

.left-align-content {
    text-align: left;
    margin-left: 100px;
    font-size: 20px;
    width: 1500px;
}

.faq-container {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.faq-item {
    background: yellow;
    width: 900px;
    margin-bottom: 50px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.faq-question {
    font-weight: bold;
    color: black;
    position: relative;
    font-size: larger;
}

.arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
}

.faq-answer {
    padding-top: 10px;
    color: black;
    font-size: larger;
}

.faq-section {
    text-align: left;
    padding: 5px;
}

.faq-heading {
    text-align: center;
    font-size: 30px;
}
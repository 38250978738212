.share-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0 0 20px;
  padding: 10px;
}

.share-buttons button {
  display: flex;
  align-items: center;
  background-color: white;
  color: white;
  border: solid #ffd700 2px;
  padding: 0px 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  border-radius: 5px;
  transition: background-color 0.3s, font-weight 0.3s, font-size 0.3s,
    color 0.3s;
}

.share-fast {
  margin: auto;
}

.share-buttons a:hover {
  font-weight: bold;
  font-size: 20px;
  transform: scale(1.5);
  transition: 0.25s;
}

.twitter-logo {
  width: 35px;
  position: relative;
  top: 20px;
  border: #ffd700, solid, 2px;
  border-radius: 5px;
  padding: 6px;
}

.share-buttons button:hover {
  font-weight: bold;
  font-size: 20px;
  color: white;
  transform: scale(1.5);
  transition: 0.25s;
  /* Ensure text color stays black on hover */
}

.share-buttons button {
  background-color: white;
  width: 130px;
  border: 2px solid #ffd700;
  color: black;
}

.share-buttons button:hover {
  background-color: white;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.share-buttons button svg {
  margin-right: 5px;
  font-size: 40px;
  /* Increase icon size */
}

.share-buttons a svg {
  margin-right: 0px;
  font-size: 35px;
  width: 35px;
  position: relative;
  top: 20px;
  border: #ffd700, solid, 2px;
  border-radius: 5px;
  padding: 6px;
}

.share-buttons a svg {
  color: #25d366;
  /* WhatsApp green */
}

.share-buttons a[href*="facebook.com"] svg {
  color: #3b5998;
  /* Facebook blue */
}

.share-buttons a[href*="linkedin.com"] svg {
  color: #0077b5;
  /* LinkedIn blue */
}

.share-buttons a[href*="pinterest.com"] svg {
  color: #bd081c;
  /* Pinterest red */
}

.share-buttons a[href*="instagram.com"] svg {
  color: #e1306c;
  /* Instagram pink */
}

.share-buttons a[href*="twitter.com"] svg {
  color: #1da1f2;
  /* Twitter blue */
}

.share-buttons button:hover svg {
  color: black;
  /* Change icon color to black on hover */
}

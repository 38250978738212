.contact-us {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  margin-top: 20px;
}

.c-us-h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-group-query {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.c-us-label {
  flex: 0 0 30%;
  margin-right: 10px;
}

.c-us-input,
.c-us-textarea,
.react-tel-input .form-control {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 0px;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding is included in the width and height */
}

.c-us-input,
.react-tel-input .form-control {
  height: 47px;
  width: 71px;
  background-color: #001f3f;
  text-align: left;
  color: #ffd700;
  position: relative;
  left: 39px;
  top: -1px;
  font-size: 20px;
}

.c-us-textarea {
  height: 80px; /* Increased height for textarea */
  resize: vertical;
  width: 90% !important; /* Full width */
  background-color: #001f3f;
  color: #ffd700;
}

@media (max-width: 600px) {
  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group-query {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -60px;
    width: 347px;
    height: 200px;
  }

  .c-us-label {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .c-us-input,
  .c-us-textarea,
  .react-tel-input .form-control {
    width: 60px;
    font-size: 15px;
  }

  .c-us-textarea {
    height: 100px;
    margin-top: -30px;
  }
}

.c-us-submit {
  display: block;
  width: 90%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: yellow;
  color: black;
  font-size: 20px;
  cursor: pointer;
  margin: 0 auto;
}

.c-us-submit:hover {
  background-color: orangered;
}

.submission-message {
  font-size: 30px;
  font-weight: bold;
  color: green;
  text-align: center;
  margin-top: 20px;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  background-color: #001f3f;
  color: #ffd700;
}

.react-tel-input .country-list .country:hover {
  background-color: lightseagreen;
  color: #001f3f;
  text-align: left;
}

.mouse-follower {
  position: fixed; /* Use fixed positioning */
  pointer-events: none; /* Prevents the message from interfering with mouse events */
  padding: 5px;
  z-index: 10;
  border-radius: 5px;
  font-size: 16px; /* Set the font size */
  font-weight: bold; /* Make the font bold */
  /* Default color, can be overridden by inline styles */
  color: black;
}

.mouse-follower.over-header-or-footer {
  color: white; /* Change text color based on the hovering condition */
}

@media (max-width: 1250px) {
  .mouse-follower {
    display: none; /* Hide on small screens */
  }
}

.bread-home {
  text-decoration: none;
  color: #ffd700;
}

.breadcrumb {
  display: flex;
  background-color: #001f3f;
  border-radius: 10px;
  color: #ffd700;
  list-style-type: none;
  padding: 10px;
  margin: 5px;
  margin-left: -12px;
  max-width: fit-content;
  font-size: 20px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 24px; /* Make the arrow bigger */
  font-weight: bold; /* Make the arrow bold */
  position: relative; /* Position relative for top adjustment */
  top: -1px; /* Adjust this value as needed to move the arrow upwards */
}

@media (max-width: 768px) {
  .breadcrumb {
    font-size: 16px; /* Adjust this value as needed */
  }
}

@media (max-width: 565px) {
  .breadcrumb {
    font-size: 14px; /* Adjust this value as needed */
  }
}

@media (max-width: 500px) {
  .breadcrumb {
    font-size: 11px; /* Adjust this value as needed */
  }
}

.no-scroll {
  overflow: hidden;
}

.header2-content {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #001f3f;
  flex-wrap: nowrap;
  z-index: 100;
  height: 85px;
}

.edit-button2 {
  background-color: orange;
  width: 183px;
  height: 43px;
  font-weight: bold;
  color: #001f3f;
  border: none;
  padding: 5px;
  padding-right: 5px;
  cursor: pointer;
  font-size: 23px;
  border-radius: 25px;
  vertical-align: middle;
  line-height: 1;
  margin-top: 0.3px;
}

.edit-button2.disabled {
  background-color: #ccc; /* Greyed out color */
  color: #666; /* Text color for disabled state */
  cursor: not-allowed; /* Show a not-allowed cursor */
  pointer-events: none; /* Prevent interactions */
}

.header2-logo-and-h1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header2-website-name {
  background-color: #001f3f;
  max-width: fit-content;
  padding: 10px;
  padding-right: 10px;
  margin: 10px;
  margin-top: 25px;
  text-align: left;
  line-height: 50px;
}

.header2-h1 {
  color: white;
  font-size: 30px;
  margin-top: 40px;
  text-decoration: underline #001f3f;
  top: -57px;
  right: -18px;
  position: relative;
}

.header2-nav-logo {
  background-color: #001f3f;
  line-height: 10px;
  margin: 10px;
  padding: 10px;
  margin-top: 16px;
}

.header2-logo-image {
  width: 194px;
  height: 57.98px;
  margin-left: 14px;
  margin-bottom: 3px;
  position: relative;
  top: -12px;
  right: 12px;
}

/* Hamburger button styles */
.hamburger {
  position: relative;
  display: block;
  width: 37px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  margin-top: 40px;
  top: -19px;
  right: 15px;
}

.hamburger-label {
  color: #ffd700;
  position: relative;
  top: -18px;
  right: 17px;
}

.hamburger:hover {
  background-color: #001f3f;
}

.hamburger .bar,
.hamburger:after,
.hamburger:before {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ffd700;
  margin: 6px 0px;
  transition: 0.4s;
}

.hamburger.is-active::before {
  transform: rotate(-45deg) translate(-8px, 9px);
}

.hamburger.is-active::after {
  transform: rotate(45deg) translate(-5px, -6px);
}

.hamburger.is-active .bar {
  opacity: 0;
}

/* Dropdown menu styles */
.dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 98; /* Ensure the dropdown menu is below header2-content */
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 10px;
  margin-top: 67px;
  text-align: center;
  display: block;
}

.dropdown-menu li {
  margin: 20px 0;
}

.dropdown-menu a {
  color: #001f3f;
  text-decoration: none;
  font-size: 25px;
}

/* Nested dropdown menu styles */
.nested-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  overflow-y: auto; /* Add this line to enable vertical scrolling */
}

.nested-dropdown.show {
  display: flex;
}

.nested-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 10px;
  text-align: center;
  display: block;
}

.nested-dropdown li {
  margin: 20px 0;
}

.nested-dropdown a {
  color: #001f3f;
  text-decoration: none;
  font-size: 20px;
}

.header2-go-back-numerology {
  background-color: rgba(128, 128, 128, 0.587);
  color: white !important;
  margin-top: 1295px;
}

.header2-go-back-numerology:hover {
  background-color: rgba(128, 128, 128, 0.747);
}

.header2-go-back-compatibility {
  background-color: rgba(128, 128, 128, 0.587);
  color: white !important;
  margin-top: -98px;
}

.header2-go-back-compatibility:hover {
  background-color: rgba(128, 128, 128, 0.747);
}

/* Styles for screen widths of 500 pixels or less */
@media (max-width: 500px) {
  .header2-h1 {
    display: none;
  }

  figcaption {
    display: block;
  }
}

.header2-chat-now {
  background-color: lawngreen;
}

.header2-chat-now:hover {
  background-color: rgb(154, 244, 65);
}

.PersonalYear {
    text-align: center;
}

/* Rest of the page styles */
body {
    background-color: #282c34;
    color: white;
}

/* Adjustments for select elements */
select {
    width: 200px;
    height: 40px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 20px;
}

button {
    width: 220px;
    height: 50px;
    font-size: 25px;
    background-color: yellow;
    /* Set button color */
    color: white;
    /* Text color */
    border: none;
    /* Remove border */
    border-radius: 8px;
    /* Rounded corners */
    cursor: pointer;
    /* Cursor on hover */
    transition: background-color 0.3s;
    /* Smooth transition */
}

.label {
    font-size: large;
}

.special {
    display: inline-block;
    border: solid white 5px;
    padding: 10px;
    border-radius: 25px;
}

button:hover {
    background-color: darkorange;
    /* Darker background color on hover */
}

.highlight {
    color: darkgreen;
    font-weight: bold;
}

/* Left-align content below the Submit button */
.left-align-content {
    text-align: left;
    margin-left: 100px;
    /* Add margin to the left */
    font-size: 20px;
}

.faq-container {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.faq-item {
    background: yellow;
    width: 900px;
    margin-bottom: 50px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.faq-question {
    font-weight: bold;
    color: black;
    position: relative;
    font-size: larger;
}

.arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
}

.faq-answer {
    padding-top: 10px;
    color: black;
    font-size: larger;
}

.faq-section {
    text-align: left;
    border: solid black 5px;
    padding: 5px;
}

.faq-heading {
    text-align: center;
    font-size: 30px;
}

/* Add this style to left-align the API response content */
.api-response {
    text-align: left;
    width: 80%;
    /* Adjust the margin as needed */
    font-size: 15px;
}

.personal-year {
    margin-left: -250px;
}

.p-year {
    text-align: center;
}
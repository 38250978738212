.Balance {
  text-align: center;
}

/* Rest of the page styles */
body {
  background-color: #282c34;
  color: white;
}

input {
  width: 500px;
  height: 35px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 30px;
}

.special {
  display: inline-block;
  border: solid white 5px;
  padding: 10px;
  border-radius: 25px;
}

.label {
  font-size: large;
}

button {
  width: 220px;
  height: 50px;
  font-size: 25px;
  background-color: #007bff;
  /* Set button color */
  color: white;
  /* Text color */
  border: none;
  /* Remove border */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition */
}

button:hover {
  background-color: #0056b3;
  /* Darker background color on hover */
}

.highlight {
  color: darkgreen;
}

/* Left-align content below the Submit button */
.left-align-content {
  text-align: left;
  margin-left: 20px;
  /* Add margin to the left */
  font-size: 20px;
}

.sharebuttons {
  text-align: center;
}

.faq-container {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.faq-item {
  background: yellow;
  width: 900px;
  margin-bottom: 50px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  color: black;
  position: relative;
  font-size: larger;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
}

.faq-answer {
  padding-top: 10px;
  color: black;
  font-size: larger;
}

.faq-section {
  text-align: left;
  padding: 5px;
}

.faq-heading {
  text-align: center;
  font-size: 30px;
}

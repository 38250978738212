/* Home.css */

html,
body {
  height: 100%;
  margin: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
}

.main-heading {
  font-size: 3em;
  text-align: center;
  margin-bottom: 20px;
  color: #001f3f;
}

.subheading {
  font-size: 1em;
  text-align: center;
  margin: 5px 0;
  color: #001f3f;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 60px 0;
  position: relative;
}

.card {
  background-color: #f9f9f9;
  border: 5px solid #ffd700;
  border-radius: 8px;
  padding: 10px;
  padding-top: 45px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  z-index: 1;
}

.card::before {
  content: "";
  display: block;
  height: 80px;
  background-color: #ffd700;
  width: 205px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  z-index: -1;
}

.card-heading {
  font-size: 1.4em;
  margin: 0;
  color: #001f3f;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 200px;
}

.card-description {
  font-size: 1em;
  color: #001f3f;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card:hover {
  transform: scale(1.3);
  z-index: 10;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

.card:hover .card-description {
  font-style: normal;
}

.column-1,
.column-3,
.column-5 {
  margin-top: -10px;
  margin-bottom: 80px;
}

.column-2,
.column-4 {
  margin-top: 100px;
  margin-bottom: 0px;
}

/* Responsive grid columns */
@media (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 60px;
    /* Increased vertical gap */
  }

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    margin-top: -10px;
    margin-bottom: 80px;
  }
}

@media (max-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
    /* Increased vertical gap */
  }

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    margin-top: -10px;
    margin-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    /* Increased vertical gap */
  }

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    margin-top: -10px;
    margin-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .card-grid {
    grid-template-columns: 1fr;
    gap: 60px;
    /* Increased vertical gap */
  }

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    margin-top: -10px;
    margin-bottom: 80px;
  }

  .card-heading {
    font-size: 1.2em;
  }

  .card-description {
    font-size: 0.8em;
  }
}

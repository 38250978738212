.Karmic {
  text-align: center;
}

body {
  background-color: white;
  color: #001f3f;
  height: auto;
}

.h3-center {
  text-align: center;
}

.h2-center {
  text-align: center;
}

.h1-center {
  text-align: center;
}

.personality-number-hr {
  margin: 30px auto;
  padding: 0;
  border: 2px solid #ffe86d;
  height: 0px;
  width: 90%;
}
input {
  width: fit-content;
  max-width: 500px;
  height: 35px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: -5px;
  background-color: #001f3f;
  color: #ffd700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
}

@media (max-width: 480px) {
  input,
  button {
    width: 95%;
  }
}

@media (max-width: 768px) {
  input {
    width: 90%;
    font-size: 18px;
  }
}

@media (max-width: 1050px) {
  .faq-container {
    padding: 10px;
    border-width: 2px;
  }
}

select {
  width: 150px; /* Adjust width as needed */
  height: 40px;
  border-radius: 8px;
  font-size: 20px; /* Adjust font size as needed */
  margin: 10px; /* Remove default margin */
  background-color: #001f3f;
  color: #ffd700;
  text-align: center;
  text-align-last: center; /* Centering the text in the dropdown for some browsers */
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add shadow */
}

select option {
  text-align: left;
}

.react-datepicker__year-select {
  margin-right: 30px;
}

.react-datepicker__month-select {
  margin-left: 30px;
}

.react-datepicker__month-container {
  width: 100%;
  background-color: #001f3f;
}

.remove-dob {
  width: 80%;
  max-width: fit-content;
  max-height: fit-content;
  margin-right: 5px;
  height: 35px;
  font-size: 25px;
  background-color: orangered;
  /* Set button color */
  color: white;
  /* Text color */
  border: solid black 2px;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition */
}

.dob-field {
  margin-bottom: -10px;
}

button {
  width: 220px;
  margin-right: 5px;
  margin-top: 20px;
  height: 50px;
  font-size: 25px;
  background-color: #ffd700;
  /* Set button color */
  color: #001f3f;
  /* Text color */
  border: none;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition */
}

button:hover {
  background-color: darkorange;
  /* Darker background color on hover */
}

.special {
  position: relative;
  display: inline-block;
  border: solid #ffd700 5px;
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 5); /* Add shadow */
}

@media (min-width: 600px) {
  .special {
    min-width: 545px;
  }
}

.label {
  font-size: large;
  margin-bottom: 10px;
}

.highlight {
  color: darkgreen;
}

.left-align-content-karmic {
  text-align: left;
  margin-left: 150px;
  margin-right: 150px;
  font-size: 20px;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.center-image-compatibility {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  height: auto;
}

.center-image-loshu1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1000px;
  height: auto;
}

.center-image-loshu2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.center-image-chaldean {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 600px;
  height: auto;
}

.center-image-jersey {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  height: auto;
}

.center-image-planet-day {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
  height: auto;
}

.center-image-pythagorean {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 700px;
  height: auto;
}

@media (max-width: 480px) {
  .center-image {
    min-width: 250px;
    margin-bottom: 10px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.sharebuttons {
  text-align: center;
}

.faq-container {
  width: 100%;
  min-width: fit-content;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  border: solid #ffd700 5px;
  border-radius: 20px;
}

@media (max-width: 1050px) {
  .faq-item {
    width: 100%;
  }
}

.faq-item {
  background: white;
  border-bottom: solid grey 1px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: -10px;
  padding: 20px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  color: black;
  position: relative;
  font-size: larger;
  text-align: left;
}

.faq-question:hover {
  background-color: gainsboro;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: translateY(-50%);
  color: #ffd700;
  width: 25px;
  height: 25px;
  border: 2px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
}

.faq-answer {
  padding-top: 10px;
  color: black;
  font-size: larger;
}

.faq-answer:hover {
  background-color: gainsboro;
}

.faq-section {
  text-align: left;
  padding: 5px;
  width: 86%;
}

.faq-heading {
  text-align: center;
  font-size: 30px;
}

.tandc {
  margin: 30px;
}

.tandc-h1 {
  text-align: center;
}

.gen-faq-header {
  text-align: center;
  margin-bottom: 50px;
}

.react-datepicker__day {
  background-color: #001f3f;
  color: #ffd700;
  font-weight: bold;
  font-size: 15px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  background-color: #001f3f;
}

.react-datepicker__day-name {
  color: #ffd700;
  font-weight: bold;
  font-size: 15px;
}

h2.react-datepicker__current-month {
  padding: 0;
  margin: 0;
  color: #ffd700;
}

.directions-container {
  display: flex;
  justify-content: space-around; /* Adjust as needed */
  margin-top: 20px; /* Adjust spacing */
}

.directions-box {
  width: 45%;
  height: 180px;
  padding: 15px;
  margin: 5px;
  border: 3px solid #001f3f;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 17px;
}

.directions-box h3 {
  font-size: 15px;
  margin-bottom: 10px;
}

.directions-box ul {
  list-style-type: none;
  padding: 0;
}

.directions-box ul li {
  margin-bottom: 8px;
}

/* Add this to your CSS file or component styles */
.monthly-predictions {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Responsive grid */
  gap: 20px; /* Adjust the gap between items as needed */
}

.month-box {
  padding: 20px;
  border-radius: 8px;
  border: solid 3px #ffe86d;
}

.month-name {
  font-size: 24px;
  color: #001f3f;
}

.month-hr {
  background-color: #ffd700;
  height: 1px;
  border: 1px;
}

.month-prediction {
  color: #001f3f;
}

#breadcrumbs {
  border: none;
  margin-top: -25px;
}

.share-line {
  position: relative;
  bottom: -19px;
}

#breadcrumb-and-rate-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: to vertically center align items */
  padding: 10px; /* Optional: Add padding if needed */
  border: none;
  position: relative;
  top: -17px;
}

#breadcrumbs1 {
  border: none;
}

.rate-limit {
  margin-right: 10px;
}

.mobile-input {
  display: flex;
  align-items: center;
}

.country-flag-dropdown {
  margin-right: 10px; /* Adjust spacing between dropdown and input as needed */
}

.main-mobile-number {
  position: relative;
  left: 38px;
  top: -8px;
  width: 280px;
  text-align: left;
}

@media (max-width: 600px) {
  .main-mobile-number {
    position: relative;
    left: 38px;
    top: -8px;
    width: 200px;
    text-align: left;
    font-size: 15px;
  }
}

.error-message-mobile {
  position: relative;
  top: 10px;
}

.main-mobile-number1 {
  position: relative;
  left: 38px;
  top: -8px;
  width: 278px;
  text-align: left;
}

@media (max-width: 400px) {
  .main-mobile-number1 {
    position: relative;
    left: 104px;
    top: -58px;
    width: 196px;
    text-align: left;
  }
}

.c-us-input1 {
  width: 90%;
}

/*EXAMPLE UI STYLES HERE */

.example-container {
  border: white;
  display: flex;
  width: 100%;
  max-width: 1400px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 65px;
}

.example-container-last {
  border: white;
  display: flex;
  width: 100%;
  max-width: 1400px;
  justify-content: center;
  flex-wrap: wrap;
}

.example-container > div {
  padding: 10px;
  width: 340px;
  height: 250px;
  margin: 5px;
  text-align: center;
  font-size: 2em;
  color: black;
  text-align: center;
  background-color: white;
}

.example-container-last > .boxLast {
  padding: 10px;
  width: 340px;
  height: 250px;
  margin: 5px;
  text-align: center;
  font-size: 2em;
  color: black;
  text-align: center;
  background-color: white;
}

.example-container > div > .example-number {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40%;
  background-color: #ffd700;
  width: 25px;
  height: 23px;
  padding: 10px;
  border: #ffd700 solid 2px;
  border-radius: 50%;
  position: relative;
  top: -155px;
  right: -142px;
  z-index: 2;
}

.example-container-last > div > .example-number {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40%;
  background-color: #ffd700;
  width: 25px;
  height: 23px;
  padding: 10px;
  border: #ffd700 solid 2px;
  border-radius: 50%;
  position: relative;
  top: -155px;
  right: -142px;
  z-index: 2;
}

.example-step1,
.example-step2,
.example-step3,
.example-step4,
.example-step5,
.example-step6 {
  background-color: white;
  font-size: 20px;
  color: #001f3f;
  text-align: left;
  padding: 10px;
  height: 180px;
  margin-top: -160px;
  border: solid rgb(220, 220, 220) 2px;
  border-radius: 10px;
}

.example-line {
  height: 5px;
  background-color: #ffd700;
  border: none;
  position: relative;
  top: -284px;
  width: 380px;
  right: 10px;
  z-index: 1;
}

.example-line-dotted {
  height: 0px;
  border: 2px dashed #ffd700;
  position: relative;
  top: -264px;
  width: 450px;
  right: 48px;
  z-index: 1;
}

.example-line-last {
  height: 5px;
  background-color: #ffd700;
  border: none;
  position: relative;
  top: -264px;
  width: 300px;
  right: -5px;
  z-index: 1;
}

.example-line-dotted-last {
  height: 0px;
  border: 2px dashed #ffd700;
  position: relative;
  top: -284px;
  width: 261px;
  right: 46px;
  z-index: 1;
}

/*When its time for a SINGLE COLUMN*/

@media (max-width: 400px) {
  .example-container {
    flex-direction: column;
  }

  .example-line {
    height: 5px;
    background-color: #ffd700;
    border: none;
    position: relative;
    top: -284px;
    width: 287px;
    right: 5px;
    z-index: 1;
  }

  .example-line-dotted {
    height: 0px;
    border: 2px dashed #ffd700;
    position: relative;
    top: -264px;
    width: 332px;
    right: 29px;
    z-index: 1;
  }

  .example-line-dotted-last {
    height: 0px;
    border: 2px dashed #ffd700;
    position: relative;
    top: -284px;
    width: 300px;
    right: 24px;
    z-index: 1;
  }

  .example-line-last {
    height: 5px;
    background-color: #ffd700;
    border: none;
    position: relative;
    top: -264px;
    width: 287px;
    right: 5px;
    z-index: 1;
  }

  .example-container > div {
    padding: 10px;
    width: 275px;
    height: 250px;
    margin: 5px;
    margin-left: 11px;
    text-align: center;
    font-size: 2em;
    color: black;
    text-align: center;
    background-color: white;
  }

  .example-container-last > .boxLast {
    padding: 10px;
    width: 275px;
    height: 250px;
    margin: 5px;
    margin-left: -12px;
    text-align: center;
    font-size: 2em;
    color: black;
    text-align: center;
    background-color: white;
  }

  .example-container > div > .example-number {
    font-size: 20px;
    margin-top: 40%;
    background-color: #ffd700;
    width: 25px;
    height: 23px;
    padding: 10px;
    border: #ffd700 solid 2px;
    border-radius: 50%;
    position: relative;
    top: -155px;
    right: -114px;
  }

  .example-container-last > .boxLast > .example-number {
    font-size: 20px;
    margin-top: 40%;
    background-color: #ffd700;
    width: 25px;
    height: 23px;
    padding: 10px;
    border: #ffd700 solid 2px;
    border-radius: 50%;
    position: relative;
    top: -155px;
    right: -114px;
  }
}

@media (min-width: 405px) and (max-width: 510px) {
  .example-container {
    flex-direction: column;
  }

  .example-container > div {
    padding: 10px;
    width: 340px;
    height: 250px;
    margin: 5px;
    margin-left: 36px;
    text-align: center;
    font-size: 2em;
    color: black;
    text-align: center;
    background-color: white;
  }

  .example-container > div > .example-number {
    font-size: 20px;
    margin-top: 40%;
    background-color: #ffd700;
    width: 25px;
    height: 23px;
    padding: 10px;
    border: #ffd700 solid 2px;
    border-radius: 50%;
    position: relative;
    top: -155px;
    right: -140px;
  }
}

@media (min-width: 400px) and (max-width: 410px) {
  .example-container {
    flex-direction: column;
  }

  .example-container > div {
    padding: 10px;
    width: 275px;
    height: 250px;
    margin: 5px;
    margin-left: -10px;
    text-align: center;
    font-size: 2em;
    color: black;
    text-align: center;
    background-color: white;
  }

  .example-container > div > .example-number {
    font-size: 20px;
    margin-top: 40%;
    background-color: #ffd700;
    width: 25px;
    height: 23px;
    padding: 10px;
    border: #ffd700 solid 2px;
    border-radius: 50%;
    position: relative;
    top: -155px;
    right: -114px;
  }

  .example-line {
    height: 5px;
    background-color: #ffd700;
    border: none;
    position: relative;
    top: -264px;
    width: 287px;
    right: 5px;
    z-index: 1;
  }

  .example-line-last {
    height: 5px;
    background-color: #ffd700;
    border: none;
    position: relative;
    top: -264px;
    width: 287px;
    right: 5px;
    z-index: 1;
  }
}

.result-small-box {
  border: 5px solid #ffd700;
  display: flex;
  padding: 0px 10px;
  margin-top: 20px;
  margin-right: 5px;
  border-radius: 8px;
  font-size: 18px;
  width: 150px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.result-small-box-loshu {
  border: 5px solid #ffd700;
  display: flex;
  padding: 0px 10px;
  margin-top: 20px;
  margin-right: 5px;
  border-radius: 8px;
  font-size: 18px;
  width: 180px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.result-small-box-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  flex-wrap: wrap;
}

.result-small-box p {
  margin: 5px 0px;
  text-align: center;
}

.result-small-box-loshu p {
  margin: 5px 0px;
}

.result-small-box p b {
  font-size: 50px;
}

.input-and-result-window {
  display: flex;
  gap: 20px;
  align-items: stretch;
}

.input-and-result-window-kua {
  display: flex;
  gap: 20px;
  align-items: stretch;
}

@media (max-width: 1120px) {
  .input-and-result-window {
    flex-direction: column;
  }
}

@media (max-width: 1240px) {
  .input-and-result-window-kua {
    flex-direction: column;
  }
}

.result-window {
  text-align: left !important;
  font-size: 20px;
  background-color: #ffd700;
  color: #001f3f;
  max-width: 500px;
  border: solid 5px #ffe86d;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-height: 270px; */
  transition: height 5s ease, padding 5s ease;
}

.example-h1 {
  margin-top: 40px;
  margin-bottom: -30px;
}

.missing-digits {
  margin-top: -40px;
  margin-bottom: 10px;
}

.result-window > p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.kua-result-window {
  font-size: 25px;
}

.house-note {
  margin-top: 50px;
  margin-bottom: -10px;
}

.kua-direction-label {
  display: inline-block;
  margin-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.kua-direction-answer {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
}

.directions-box td {
  padding: 0px 5px;
  padding-bottom: 10px;
}

.result-window > h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  margin: 23px 0;
}

.result-window > h4 {
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .input-and-result-window {
    flex-direction: column;
  }
}

.kua-only {
  font-size: 18px;
  gap: 5px;
}

/* ================================================================================================================================================= */

/* SIGN UP AND LOGIN STYLES */

.signup-and-login {
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.not-a-member {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 20px;
}

.sign-up {
  border: solid 2px gray;
  border-radius: 8px;
  height: fit-content;
  margin: 50px 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
}

#termsCheckbox {
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: -0.5px;
  width: auto;
}

@media (max-width: 950px) {
  .signup-and-login {
    flex-direction: column;
    align-items: center;
  }
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 20px;
}

.sign-up-form label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: white;
  position: relative;
}

.password-error {
  color: red;
  width: fit-content;
  font-size: 14px;
  width: 90%;
  text-align: left;
  margin: 0;
  padding: 0px;
  position: absolute;
  bottom: -31px;
  right: -79px;
  margin-bottom: 5px;
}

.password-error--reset {
  width: 100%;
}

.password-error--otp {
  width: 122%;
}

.sign-up-form input {
  padding: 0px;
  font-size: 16px;
  margin-top: 0px;
  text-align: left;
  width: 300px;
  padding-left: 10px;
}

.sign-up-form button {
  padding: 10px 0px;
  font-size: 18px;
  background-color: #ffd700;
  border: none;
  cursor: pointer;
  color: black;
  margin: 0px auto;
  margin-top: 10px;
}

.sign-up-form a {
  margin: 0px auto;
}

.delete-account {
  color: red;
}

.login-link {
  background-color: #ffd700;
  border-radius: 8px;
  padding: 8px 30px;
  cursor: pointer;
  color: #001f3f;
}

.signup-conditions {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}

.signup-conditions a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.sign-up-btn {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.yes-or-no {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.yes-button {
  padding: 0px;
  margin: 0px;
  height: auto;
  width: 70px;
}
.small {
  font-size: 13px;
  color: #001f3f;
}

.signup-span {
  margin-right: 10px;
  color: #001f3f;
}

.forgot-password {
  align-self: center;
  font-size: 20px;
  text-decoration: none;
}

.reset-password {
  background-color: #ffd700;
  color: #001f3f;
  text-decoration: none;
  padding: 10px 20px;
  border: #ffd700;
  font-size: 20px;
  border-radius: 8px;
  text-align: center;
}

#account-section {
  display: none;
}

.google-and-facebook-icons-div {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.google-and-facebook-icons-div p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px;
}

.google-and-facebook-icons-svg {
  box-shadow: 0px 0px 5px 0px black;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}

.google-fb-icon-button {
  background-color: white;
  box-shadow: 0px 0px 2px 0px black;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  height: 42px;
  width: auto;
  margin: 0;
}

.google-fb-icon-button:hover {
  box-shadow: 0px 0px 20px 0px black;
  background-color: white;
}

.google-fb-icon-button:active {
  box-shadow: 0px 0px 10px 0px black;
  background-color: white;
}

/* ================================================================================================================================================== */

/* SERVICES PAGE STYLES */

.service-container {
  display: flex;
  padding: 80px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center; /* Space between cards */
  gap: 50px; /* Optional: space between the cards */
}

.services {
  flex: 0 0 22%; /* Fixed width, change as needed */
  height: 200px; /* Fixed height */
  min-width: 300px;
  background-color: white; /* Background color for the card */
  border: 5px solid #ffd700; /* Optional: Border for the card */
  border-radius: 10px;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.services h2 {
  background-color: #ffd700;
  width: fit-content;
  padding: 5px 10px;
  margin: 0;
}

.buy-now {
  text-decoration: none;
  background-color: #ffd700;
  color: #001f3f;
  padding: 5px 10px;
  font-weight: bold;
  letter-spacing: 0px;
}

/* ---------------------------- */
/* PURCHASE SERVICE */
/* ---------------------------- */

.new-user-pay-div {
  display: flex;
  justify-content: center;
}

.new-user-pay-link {
  margin: 5px 10px;
  background-color: #ffd700;
  color: #001f3f;
  padding: 5px 10px;
  border-radius: 8px;
}

.purchaseService h1 {
  text-align: center;
  margin-bottom: 40px;
}

.purchaseService h3 {
  text-align: center;
  font-weight: 400;
}

.purchase-service-container {
  padding-top: 20px;
}

.purchase-token {
  margin-top: 0px;
  box-shadow: none;
  position: absolute;
  left: 10px;
}

.disabled-link {
  pointer-events: none; /* Prevents clicking */
  background-color: gainsboro;
}

.token-plans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.vertical-line {
  border-left: 5px solid #001f3f;
  height: 500px;
  margin-left: 20px;
}

@media (max-width: 950px) {
  .vertical-line {
    display: none;
  }

  .horizontal-line {
    border-top: 5px solid #001f3f;
    width: 500px;
    margin-top: -47px;
  }
}

.selected {
  box-shadow: 0px 0px 20px 5px;
  border: solid #ffd700 7px;
}

.radio-and-title {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}
.eye {
  margin: 0;
  padding: 0;
  fill: #ffd700;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.eye--sign-up {
  right: 60px;
}

/* =================================================================================================================================================== */

/* CREDIT BALANCE PAGE STYLES */

.credit-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credit-balance h1 {
  text-align: center;
}

.credit-balance h2 {
  background-color: #ffd700;
  height: fit-content;
  width: fit-content;
  padding: 5px 10px;
  padding-right: 50px;
  border-radius: 10px;
  margin: 0px;
  color: #001f3f;
}

.balance-and-tokens {
  /* padding: 0 20px; */
  width: 95vw;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buy-tokens {
  display: inline-block;
  margin: 0px;
  padding: 5.5px 10px;
  font-size: 24px;
  font-weight: bold;
  height: fit-content;
  border-radius: 10px;
}

.credit-balance h3 {
  text-align: center;
  font-size: 30px;
}

.transaction-history {
  margin: 20px auto;
  width: 99%;
}

.transaction-history table {
  width: 100%;
  border: 6px solid #001f3f;
  border-collapse: separate;
  border-spacing: 0px;
  border-radius: 10px;
}

.transaction-history th,
.transaction-history td {
  padding: 20px;
  text-align: left;
}

.transaction-history td {
  border: 1px solid gray;
}

.transaction-history th {
  background-color: #ffd700;
  color: #001f3f;
  font-weight: bold;
  border: 1px solid #001f3f;
}

.transaction-history tbody tr:hover {
  background-color: white;
}

.transaction-history .s-no {
  width: 0px;
}

.transaction-history td p {
  margin: 0;
  padding: 0;
}

.transaction-history tr {
  height: 60px;
}

.complete-your-details {
  color: blue;
  text-underline-offset: 5px;
}

/* -------------------------------------------------------------------------------------------------------- */

/* MY PROFILE */

.profile-container {
  display: flex;
  padding: 20px;
}

.profile-info-1 {
  max-width: 600px; /* Adjust as needed */
  padding: 20px;
  border-right: 5px solid #ddd;
}

.profile-info-2 {
  padding: 20px;
  flex: 1;
}

.profile-item {
  display: flex;
  align-items: center;
  height: 50px;
}

.profile-item label {
  width: 160px; /* Set a fixed width for labels */
  font-weight: bold;
}

.profile-item p {
  margin-right: 40px;
}

.profile-item p a {
  text-decoration: none;
  background-color: #ffd700;
  color: #001f3f;
  padding: 5px 10px;
  border-radius: 8px;
}

.profile-item input {
  padding: 0px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  letter-spacing: 1.5px;
  margin: 0;
  margin-right: 10px;
  width: 130px;
}

.profile-mobile-button {
  width: 60px;
  margin-right: 0;
  margin-top: 0;
  height: 25px;
  font-size: 15px;
  background-color: #ffd700;
  color: #001f3f;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* ----------------------------------------------- */
/* PAYMENT SUCCESSFUL */
/* ----------------------------------------------- */

.payment-successful {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------------------------- */
/* RELATED TOOLS */
/* ----------------------------------------------- */

.carousel-container {
  position: relative;
  width: 100%;
  /* width: 670px;  for 1150px screen width*/
  max-width: 1000px;
  height: 355px;
  margin: 0 auto;
  /* overflow: hidden; */
}

@media (max-width: 1150px) {
  .carousel-container {
    width: 670px;
  }
}

@media (max-width: 840px) {
  .carousel-container {
    width: 336px;
  }
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carousel-images {
  display: flex;
  gap: 10px;
  transition: transform 1s ease-in-out;
}

.carousel-images img {
  width: calc(100% / 3);
  height: 100%;
  object-fit: cover;
  border: 5px solid #ffd700;
  border-radius: 8px;
}

.carousel-arrow {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgray;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #001f3f;
}

button.carousel-arrow.left-arrow,
button.carousel-arrow.right-arrow {
  height: 40px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd700;
  padding: 8px;
}

button.carousel-arrow.left-arrow {
  margin-right: 50px;
}

button.carousel-arrow.right-arrow {
  margin-left: 100px;
}

/* ------------------------------------------------------------------------------------------------------------ */

.slick-prev,
.slick-next {
  background-color: #ffd700;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  top: 46%;
  z-index: 10;
  transition: none;
}

.slick-next:hover,
.slick-prev:hover {
  background-color: #ffd700;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: #001f3f;
}

.carousel-image {
  width: 317.3px;
  height: 317.3px;
  object-fit: cover;
  border: 5px solid #ffd700;
  border-radius: 8px;
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #ffd700;
  color: white;
}

.slick-next {
  right: -60px;
  color: white;
}

.slick-prev {
  left: -60px;
  color: white;
}

/* ------------------------------------------------------------------------------------ */

.issues-remedies-container {
  display: flex; /* Use flexbox for side-by-side layout */
  justify-content: space-between; /* Space between the columns */
  margin-top: 20px; /* Optional margin for spacing */
}

.possible-issues,
.remedies {
  flex: 1; /* Each column takes equal space */
  margin-right: 20px; /* Optional margin for spacing */
}

.remedies {
  margin-right: 0; /* Remove margin for the last column */
}

.issue-item,
.remedy-item {
  margin-bottom: 5px;
}

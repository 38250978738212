.LifePath {
    text-align: center;
}

/* Rest of the page styles */
body {
    background-color: #282c34;
    color: white;
}

.result {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 1000px;
}

/* Adjustments for select elements */
select {
    width: 200px;
    height: 40px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 20px;
}

button {
    width: 220px;
    height: 50px;
    font-size: 25px;
    background-color: #007bff;
    /* Set button color */
    color: white;
    /* Text color */
    border: none;
    /* Remove border */
    border-radius: 8px;
    /* Rounded corners */
    cursor: pointer;
    /* Cursor on hover */
    transition: background-color 0.3s;
    /* Smooth transition */
}

.label {
    font-size: large;
}

.special {
    display: inline-block;
    border: solid white 5px;
    padding: 10px;
    border-radius: 25px;
}

button:hover {
    background-color: #0056b3;
    /* Darker background color on hover */
}

.highlight {
    color: darkgreen;
    font-weight: bold;
}

/* Left-align content below the Submit button */
.left-align-content {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    /* Add margin to the left */
    font-size: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
    /* This makes the content expand to fill available space */
}
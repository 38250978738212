/* Set the height of the HTML and body to 100% of the viewport height */
html,
body {
    height: 100%;
    margin: 0;
    /* Reset default margin */
    padding: 0;
    /* Reset default padding */
}

body {
    display: flex;
    flex-direction: column;
    /* Stacks content vertically */
    min-height: 100vh;
    /* Ensure it's at least the height of the viewport */
}

.main-content {
    flex: 1;
    /* Flex-grow to take available space */
    padding: 20px;
    /* Padding for the content area */
}

/* Footer.css */
footer {
    background-color: #001F3F;
    color: white;
    /* Ensure text color is consistent and readable */
    text-align: center;
    padding: 20px 0;
    /* Padding for top and bottom of the footer */
    margin-top: auto;
    /* Push the footer to the bottom */
}

footer nav {
    display: flex;
    justify-content: center;
    /* Align the navigation links to the center */
}

footer nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* Remove default margin to align properly */
    display: flex;
    gap: 10px;
    /* Add spacing between items */
}



footer nav ul li a {
    color: white;
    width: fit-content;
    text-decoration: none;
    padding: 10px 0px 0px 0px;
    background-color: #001F3F;
    font-size: 20px;
    /* Padding for clickable area increase */
}

/* Hover effect for footer navigation links */
footer nav ul li a:hover {
    text-decoration: underline;
    background-color: #001F3F;
    /* Visual cue for hover state */
}

.footer-text {
    text-align: center;
    margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 1300px) {
    footer nav ul li a {
        color: white;
        text-decoration: none;
        padding: 0px 0px 0px 0px;
        background-color: #001F3F;
        font-size: 15px;
        /* Padding for clickable area increase */
    }

}

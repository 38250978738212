.SubconsciousSelf {
  text-align: center;
}

/* Rest of the page styles */
body {
  background-color: #282c34;
  color: white;
}

input {
  width: auto;
  max-width: 500px;
  height: 35px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  input {
    width: 500px;
  }
}

.special {
  position: relative;
  display: inline-block;
  border: solid black 5px;
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 15px;
}

.label {
  font-size: large;
}

button {
  width: 220px;
  height: 50px;
  font-size: 25px;
  background-color: yellow;
  /* Set button color */
  color: black;
  /* Text color */
  border: solid black 1px;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor on hover */
  transition: background-color 0.3s;
  /* Smooth transition */
}

button:hover {
  background-color: darkorange;
  /* Darker background color on hover */
}

.highlight {
  color: darkgreen;
}

/* Left-align content below the Submit button */
.left-align-content {
  text-align: left;
  border-radius: 20px;
  border: solid #ffd700 5px;
  margin-left: auto;
  margin-top: 10px;
  margin-right: auto;
  padding: 20px 20px 20px 20px;
  /* Add padding to the left and right */
  font-size: 20px;
  max-width: 80%;
  /* Limit the max width for large screens */
}

.sharebuttons {
  text-align: center;
}

.faq-container {
  width: 100%;
  max-width: 1233px;
  margin: 20px auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  border: solid black 5px;
  border-radius: 20px;
}

.faq-item {
  background: white;
  border-bottom: solid grey 1px;
  width: 900px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  color: black;
  position: relative;
  font-size: larger;
}

.faq-answer {
  padding-top: 10px;
  color: black;
  font-size: larger;
}

.faq-section {
  text-align: left;
  padding: 5px;
  border: none;
}

.faq-heading {
  text-align: center;
  font-size: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .special {
    width: 90%;
    padding: 10px;
    border-radius: 15px;
  }

  .special .date-selectors {
    flex-direction: column;
    gap: 5px;
  }

  select {
    width: 100%;
    margin: 5px 0;
  }

  button {
    width: 100%;
    font-size: 20px;
  }

  .left-align-content {
    margin: 20px auto;
    /* Center the content horizontally */
    padding: 0 10px;
    /* Adjust padding for smaller screens */
    font-size: 18px;
  }

  .faq-item {
    width: 100%;
    max-width: 100%;
  }

  .faq-heading {
    font-size: 24px;
  }
}

/* Responsive faq-section */
@media (max-width: 1050px) {
  .faq-section {
    padding: 10px;
  }

  .faq-container {
    padding: 10px;
    border-width: 2px;
  }

  .faq-item {
    width: 100%;
  }

  .faq-heading {
    font-size: 24px;
  }
}

/* Responsive left-align-content */
@media (max-width: 768px) {
  .left-align-content {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
  }
}

@media (max-width: 565px) {
  .left-align-content {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 11px;
  }
}

@media (max-width: 565px) {
  .left-align-content {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .left-align-content {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
  }
}


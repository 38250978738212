.BiorhythmChart {
  text-align: center;
}

/* Rest of the page styles */
body {
  background-color: #282c34;
  color: white;
}

/* Adjustments for select elements */
select {
  width: 200px;
  height: 40px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 20px;
}

.faq-container {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.faq-item {
  background: yellow;
  width: 90%;
  margin-bottom: 50px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  color: black;
  position: relative;
  font-size: larger;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
}

.faq-answer {
  padding-top: 10px;
  color: black;
  font-size: larger;
}

.sharebuttons {
  text-align: center;
}

.faq-section {
  text-align: left;
  padding: 5px;
}

.faq-heading {
  text-align: center;
  font-size: 30px;
}

button {
  width: 220px;
  height: 50px;
  font-size: 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.label {
  font-size: large;
}

.special {
  display: inline-block;
  border: solid white 5px;
  padding: 10px;
  border-radius: 25px;
}

button:hover {
  background-color: #0056b3;
}

.highlight {
  color: darkgreen;
}

.left-align-content {
  text-align: left;
  margin-left: 100px;
  font-size: 20px;
}

.chart-container {
  width: 90%;
  max-width: 1320px;
  height: 512px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
